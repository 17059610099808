import _ from 'lodash';
window._ = _;

// moment.js
import moment from 'moment/moment';
import 'moment/locale/de';
moment.locale('de');
window.moment = moment;

// jQuery
import $ from 'jquery';
window.jQuery = window.$ = $

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    cluster:import.meta.env.VITE_PUSHER_APP_CLUSTER,
});

// Livewire
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse'
import Tooltip from "@ryangjchandler/alpine-tooltip";
import AlpineLazyLoadAssets from "alpine-lazy-load-assets";
import AsyncAlpine from 'async-alpine';
import '@nextapps-be/livewire-sortablejs';

AsyncAlpine.init(Alpine);
AsyncAlpine.start();

Alpine.plugin(collapse)
Alpine.plugin(Tooltip);
Alpine.plugin(AlpineLazyLoadAssets);

import {livewire_hot_reload} from 'virtual:livewire-hot-reload';
livewire_hot_reload();

Livewire.start();

// Flatpickr
import flatpickr from "flatpickr";
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/l10n/default';
import 'flatpickr/dist/l10n/de';

window.flatpickr = flatpickr;
window.rangePlugin = rangePlugin;
window.monthSelect = monthSelect;

// Powergrid
import './../../vendor/power-components/livewire-powergrid/dist/powergrid';
import "./../../vendor/power-components/livewire-powergrid/dist/powergrid.css";

// SweetAlerts2
import Swal from 'sweetalert2';
window.Swal = Swal;

// Leaflet
import L from 'leaflet/dist/leaflet';
L.Icon.Default.imagePath = '/assets/leaflet/';

// Print.js
import printJS from "print-js-updated";
window.printJS = printJS;

// Lightbox 2
import 'lightbox2/dist/js/lightbox.min';


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

